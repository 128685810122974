// context.js
import React, { createContext, useContext, useRef } from 'react';

const GlobalStateContext = createContext();

function randInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const NekoStateProvider = ({ children }) => {
    const positionRef = useRef({
        x: randInt(0, typeof window === 'undefined' ? 0 : (window.innerWidth - 100)),
        y: 0,
    });
    const velocityRef = useRef({ x: 0, y: 0 });
    const nekoRef = useRef(null);
    const nekoHatRef = useRef(null);
    const login = useRef(false);

    const state = {
        positionRef,
        velocityRef,
        nekoRef,
        nekoHatRef,
        login,
    };

    return (
        <GlobalStateContext.Provider value={state}>{children}</GlobalStateContext.Provider>
    );
};

export const useNekoState = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error('useNekoState must be used within a NekoStateProvider');
    }
    return context;
};
