//import 'hacktimer';
//import "./src/components/prism-vsc-dark-plus.css"; // Prism 主题
// import "@fancyapps/fancybox/dist/jquery.fancybox.css"; // FancyBox 样式
// import "@fancyapps/fancybox/dist/jquery.fancybox";     // FancyBox 脚本
// gatsby-browser.js
import React from 'react';
import { NekoStateProvider } from './src/components/context';

export const wrapRootElement = ({ element }) => {
  return <NekoStateProvider>{element}</NekoStateProvider>;
};

